import store from "./store";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
window.Pusher = Pusher;

const REVERB_APP_KEY = "wcirqij3lq8phfd1ms52";
const REVERB_HOST = "localhost";
const REVERB_PORT = 8090;
const REVERB_SCHEME = "http";

Pusher.logToConsole = true;

const token = store.state.token;

window.Echo = new Echo({
  authEndpoint: "https://backend.aureght.com/broadcasting/auth",
  broadcaster: "reverb",
  key: REVERB_APP_KEY,
  wsHost: REVERB_HOST,
  wsPort: REVERB_PORT ?? 80,
  wssPort: REVERB_PORT ?? 443,
  forceTLS: false,
  enabledTransports: ["ws", "wss"],
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  },
});
