import { createStore } from "vuex";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import Cookies from "js-cookie";

import apiServices from "../tools/apiServices";

interface RegisterPayload {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  company_name: string;
  country_id: number;
  company_description?: string | null;
  company_phone?: string | null;
  company_address?: string | null;
  raison_social?: string | null;
  date_creation?: string | null;
  adresse?: string | null;
  forme_juridique?: string | null;
  capital?: string | null;
  secteur_activite?: string | null;
  registre_commerce?: string | null;
  if?: string | null; // renamed field
  patente?: string | null;
  numero_tva?: string | null;
  numero_inscription_cnss?: string | null;
  ice?: string | null;
  dirigeant?: string | null;
  representant_contact?: string | null;
  fonction_representant?: string | null;
  gsm_representant?: string | null;
  email_representant?: string | null;
}

const store = createStore({
  state: {
    isAuthenticated: !!Cookies.get("ght_token"),
    user: JSON.parse(Cookies.get("user") || "{}"),
    token: Cookies.get("ght_token"),
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
  },
  mutations: {
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        // Call the login method from authAPI with credentials
        const response = await apiServices.authAPI.login({ email, password });

        // Extract data from the response
        const data = response.data;

        // Set the token and user information in cookies with an expiry date
        const expiryDate = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000 // 24 hours from now
        );

        Cookies.set("ght_token", data.token, { expires: expiryDate });
        Cookies.set("user", JSON.stringify(data.user), {
          expires: expiryDate,
        });

        // Commit the necessary mutations
        commit("SET_TOKEN", data.token);
        commit("SET_AUTHENTICATED", true);
        commit("SET_USER", data.user);
      } catch (error) {
        console.error("Login failed:", error);
        throw error;
      }
    },
    async register(
      { commit },
      {
        name,
        email,
        password,
        password_confirmation,
        company_name,
        company_description = null,
        company_phone = null,
        company_address = null,
        country_id,
        raison_social = null,
        date_creation = null,
        adresse = null,
        forme_juridique = null,
        capital = null,
        secteur_activite = null,
        registre_commerce = null,
        if: company_if = null, // 'if' is a reserved word, using 'company_if'
        patente = null,
        numero_tva = null,
        numero_inscription_cnss = null,
        ice = null,
        dirigeant = null,
        representant_contact = null,
        fonction_representant = null,
        gsm_representant = null,
        email_representant = null,
      }
    ) {
      try {
        // Prepare the payload
        const payload: RegisterPayload = {
          name,
          email,
          password,
          password_confirmation,
          company_name,
          // company_image: null,
          country_id,
          company_description,
          company_phone,
          company_address,
          raison_social,
          date_creation,
          adresse,
          forme_juridique,
          capital,
          secteur_activite,
          registre_commerce,
          if: company_if,
          patente,
          numero_tva,
          numero_inscription_cnss,
          ice,
          dirigeant,
          representant_contact,
          fonction_representant,
          gsm_representant,
          email_representant,
        };

        // Remove any null or empty values from the payload
        Object.keys(payload).forEach(
          (key) =>
            (payload[key as keyof RegisterPayload] === null ||
              payload[key as keyof RegisterPayload] === "") &&
            delete payload[key as keyof RegisterPayload]
        );

        console.log(payload);
        // Call the register method from authAPI with the payload
        const response = await apiServices.authAPI.register(payload);

        // Extract data from the response
        const data = response.data;

        // Set the token and user information in cookies with an expiry date
        const expiryDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); // 24 hours from now

        Cookies.set("ght_token", data.token, { expires: expiryDate });
        Cookies.set("user", JSON.stringify(data.user), { expires: expiryDate });

        // Commit the necessary mutations
        commit("SET_TOKEN", data.token);
        commit("SET_AUTHENTICATED", true);
        commit("SET_USER", data.user);
      } catch (error) {
        console.error("Registration failed:", error);
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        const response = await apiServices.authAPI.logout();

        // Check if the response is successful
        if (response.status !== 200) {
          throw new Error("Logout failed");
        }

        // Perform post-logout actions
        Cookies.remove("ght_token");
        Cookies.remove("user");
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", {});
        commit("SET_TOKEN", null);
        window.location.reload();
      } catch (error) {
        console.error("Logout failed:", error);
        throw error;
      }
    },
    async logoutWithoutAPI({ commit }) {
      try {
        // Perform post-logout actions
        Cookies.remove("ght_token");
        Cookies.remove("user");
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", {});
        commit("SET_TOKEN", null);
        window.location.reload();
      } catch (error) {
        console.error("Logout failed:", error);
        throw error;
      }
    },
  },
  modules: {},
});

export default store;

export const authGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const isAuthenticated = store.getters.isAuthenticated;

  // const authRoutes = ["home", "profile", "dashboard"];
  const unauthRoutes = [
    "login",
    "register",
    "forget-password",
    "conditions-utilisation",
  ];

  const routeName = (to.name ?? "").toString();

  if (isAuthenticated) {
    if (unauthRoutes.includes(routeName)) {
      next({ name: "home" });
    } else {
      next();
    }
  } else {
    if (!unauthRoutes.includes(routeName)) {
      next({ name: "login" });
    } else {
      next();
    }
  }
};
