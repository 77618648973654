import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authGuard } from "../store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/LoginPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/RegisterPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/conditions-utilisation",
    name: "conditions-utilisation",
    component: () => import("../views/auth/RegisterPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("../views/auth/RegisterPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/ProfilePage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomePage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/SearchPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/product/:id",
    name: "product",
    component: () => import("../views/products/ProductPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/fournisseur/:id",
    name: "fournisseur",
    component: () => import("../views/fournisseur/ProfilePage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/p-service/:id",
    name: "p-service",
    component: () => import("../views/p-service/ProfilePage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/p-travaux/:id",
    name: "p-travaux",
    component: () => import("../views/p-travaux/ProfilePage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/p-tce/:id",
    name: "p-tce",
    component: () => import("../views/p-tce/ProfilePage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/chat/:contactId?",
    name: "chat",
    component: () => import("../views/ChatPage.vue"),
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
