import axios from "axios";
import store from "@/store";

const baseURL = "https://backend.aureght.com/api/promoteur";

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use((config) => {
  const token = store.state.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.Accept = "application/json";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      console.error("log out");
      await store.dispatch("logoutWithoutAPI"); // Call logout action from the store
    }
    return Promise.reject(error);
  }
);

const authAPI = {
  login: (credentials: any) => axiosInstance.post("login", credentials),
  register: (credentials: any) => axiosInstance.post("register", credentials),
  profil: () => axiosInstance.get("profil"),
  logout: () => axiosInstance.post("logout"),
};

const produitsCategoriesAPI = {
  getAll: () => axiosInstance.get("produit-categories"),
};

const produitsAPI = {
  getAll: () => axiosInstance.get("produits"),
  getByID: (productID: number) => axiosInstance.get(`produits/${productID}`),
  latest: () => axiosInstance.get("produits/latest"),
  search: (params: any) => axiosInstance.get("produits/search", { params }),
};

const fournisseurAPI = {
  getAll: () => axiosInstance.get("fournisseurs"),
  getByID: (fournID: number) => axiosInstance.get(`fournisseurs/${fournID}`),
  search: (params: any) => axiosInstance.get("fournisseurs/search", { params }),
  getWithCategories: () => axiosInstance.get("fournisseurs/with-category"),
};

const servicesAPI = {
  getAll: () => axiosInstance.get("services"),
};

const pServiceAPI = {
  getAll: () => axiosInstance.get("p-services"),
  getByID: (fournID: number) => axiosInstance.get(`p-services/${fournID}`),
  search: (params: any) => axiosInstance.get("p-services/search", { params }),
};

const travauCategoriesAPI = {
  getChildrens: () => axiosInstance.get("travaux-categories/childrens"),
  getParents: () => axiosInstance.get("travaux-categories"),
};

const pTravauAPI = {
  getAll: () => axiosInstance.get("p-travaux"),
  getByID: (pTravau: number) => axiosInstance.get(`p-travaux/${pTravau}`),
  search: (params: any) => axiosInstance.get("p-travaux/search", { params }),
};

const pTceAPI = {
  getByID: (pTce: number) => axiosInstance.get(`p-tce/${pTce}`),
};

const countriesAPI = {
  getAll: () => axiosInstance.get("countries"),
  getTop: () => axiosInstance.get("countries/top-with-most-products"),
};

const contactsAPI = {
  getAll: () => axiosInstance.get("contacts"),
  addContact: (contact_id: number) =>
    axiosInstance.post("contacts", { contact_id: contact_id }),
};

const chatAPI = {
  loadMessages: (id: any) => axiosInstance.get(`messages/${id}`),
  sendMessage: (contactId: number, formData: any) =>
    axiosInstance.post(`messages/send/${contactId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};

export default {
  authAPI,
  produitsCategoriesAPI,
  countriesAPI,
  fournisseurAPI,
  pServiceAPI,
  pTravauAPI,
  pTceAPI,
  travauCategoriesAPI,
  servicesAPI,
  produitsAPI,
  contactsAPI,
  chatAPI,
};
